import { FC } from 'react'
import { useAuthState } from '../../../../store/auth/slice'
import { Box } from '@mui/material'
import { P2 } from '../../../../common/typography'

interface Props {

}

export const SubscriptionSettings: FC<Props> = () => {
  const { subscription } = useAuthState()

  return (
    <>
      {subscription && <>
        <Box mb="20px">
          <P2>Your subscription expires on {new Date(subscription?.expires).toLocaleString()}</P2>
        </Box>
      </>}
    </>
  )
}
