import { FC } from 'react'
import { Box, MenuItem, PaletteMode, Select, SelectChangeEvent, Typography } from '@mui/material'
import { setAppTheme, setLocale, useAppState } from '../../../../store/app/slice'
import { Locales } from '../../../../constants/constants'
import { useAppDispatch } from '../../../../store/store'
import EnIcon from '../../../../assets/en-icon.png'
import RuIcon from '../../../../assets/ru-icon.png'
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import { P1 } from '../../../../common/typography'

export const AppSettings: FC = () => {
  const dispatch = useAppDispatch()
  const { locale, theme } = useAppState()

  const handleChangeLocale = (event: SelectChangeEvent) => {
    dispatch(setLocale(event.target.value as Locales))
  }

  const handleChangeTheme = (event: SelectChangeEvent) => {
    dispatch(setAppTheme(event.target.value as PaletteMode))
  }

  return (
    <Box width="100%">

      <Box mb={2}>
        <P1 mr={3} mb={1}>language</P1>
        <Select value={locale} onChange={handleChangeLocale} fullWidth>
          <MenuItem value={Locales.EN}>
            <Box display="flex">
              <img src={EnIcon} alt="en-icon" width={25} height={25}/>
              <Typography ml={2}>English</Typography>
            </Box>
          </MenuItem>
          <MenuItem value={Locales.RU}>
            <Box display="flex">
              <img src={RuIcon} alt="ru-icon" width={25} height={25}/>
              <Typography ml={2}>Русский</Typography>
            </Box>
          </MenuItem>
        </Select>
      </Box>

      <Box mb={2}>
        <P1 mr={3} mb={1}>theme</P1>
        <Select fullWidth value={theme} onChange={handleChangeTheme}>
          <MenuItem value="light">
            <Box display="flex">
              <LightModeIcon sx={{ color: '#FAE100' }}/>
              <P1 ml={2}>Light</P1>
            </Box>
          </MenuItem>
          <MenuItem value="dark">
            <Box display="flex">
              <DarkModeIcon/>
              <P1 ml={2}>Dark</P1>
            </Box>
          </MenuItem>
        </Select>
      </Box>

    </Box>
  )
}
