import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useAppSelector } from '../store'
import { localStorageService } from '../../services/localStorageService'
import { IUser, Subscription } from '../../types/models/user'

export interface AuthState {
  currentUser: IUser | null
  subscription: Subscription | null
  loading: boolean
  error: string | null
  isRecaptchaSuccess: boolean
}

const initialState: AuthState = {
  currentUser: null,
  subscription: null,
  loading: true,
  error: null,
  isRecaptchaSuccess: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setCurrentUser: (state, { payload }: PayloadAction<IUser>) => {
      state.currentUser = payload
    },
    setError: (state, { payload }: PayloadAction<string | null>) => {
      state.error = payload
    },
    logout: (state) => {
      state.currentUser = null
      state.subscription = null
      localStorageService.removeAuthData()
    },
    setIsRecaptchaSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isRecaptchaSuccess = payload
    },
    setSubscription: (state, { payload }: PayloadAction<Subscription | null>) => {
      state.subscription = payload
    }
  }
})


export const authActions = authSlice.actions

export const AuthReducer = authSlice.reducer


export const useAuthState = () => {
  const state = useAppSelector(state => state.auth)
  const isAuth = !!(state.currentUser && state.currentUser?.id && state.currentUser.email)
  const isSubscriptionActive = !!(
    isAuth &&
    state.subscription &&
    state.subscription.userId === state.currentUser?.id &&
    state.subscription.expires &&
    new Date(state.subscription.expires).getTime() > Date.now()
  )
  return { ...state, isAuth, isSubscriptionActive }
}
