import { Intervals } from '../../constants/constants'

export type MarketType = 'LEVERAGE' | 'SPOT'

export interface Market {
  symbol: string
  name: string
  baseAsset: string
  quoteAsset: string
  assetType: string
  tickSize: number
  marketType: MarketType
}

export interface ChartData {
  symbol: string
  ask: [number, string, string, string, string, number][]
  bid: [number, string, string, string, string, number][]
}

export type ChartType = 'line' | 'candlestick'

export interface ChartConfig {
  height?: number
  normalize?: boolean
  chartType?: ChartType
  interval?: Intervals
  selectedCharts?: string[]
}

export interface MarketFilter {
  assetType: string | null
  name: string | null
}

export type ChartPriceType = 'ask' | 'bid'

export interface Marker {
  id: number
  botId: number
  timestamp: number
  action: 'SELL' | 'BUY' | 'HOLD'
}

export enum ChartDataSource {
  CURRENCY = 'currency',
  ORACLE = 'oracle'
}
