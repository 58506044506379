import { OracleAsset, OracleAssetAnalytics } from '../../types/models/oracle'
import { apiRTK } from '../api'


export const oracleApi = apiRTK.injectEndpoints({
  endpoints: (build) => ({
    getCapitalAssets: build.query<OracleAsset[], unknown>({
      query: () => ({ url: 'oracle/get_capital_assets' }),
      transformResponse: (response: Record<string, Omit<OracleAsset, 'epic'>>) => {
        return response ? Object.entries(response).map(([key, value]) => ({ epic: key, ...value })) : []
      }
    }),
    editRisk: build.mutation<any, { epic: string, risk: number }>({
      query: ({ epic, risk }) => ({
        url: 'oracle/save_risk_setting',
        method: 'POST',
        params: { epic, risk }
      })
    }),
    getCapitalAnalytics: build.query<OracleAssetAnalytics, { epic: string }>({
      query: ({ epic }) => ({
        url: 'oracle/analyze_capital_asset',
        method: 'POST',
        params: { epic }
      })
    })
  })
})

