import { FC, SyntheticEvent, useState } from 'react'
import { Box, MenuItem, Paper, Tab, Tabs } from '@mui/material'
import SsidChartIcon from '@mui/icons-material/SsidChart'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import TableChartIcon from '@mui/icons-material/TableChart'
import { useLocation, useNavigate } from 'react-router-dom'
import { P1 } from '../../common/typography'
import { SettingsDialog } from './settings/settingsDialog'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
// import SmartButtonIcon from '@mui/icons-material/SmartButton'


export const Toolbar: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [settingsOpened, setSettingsOpened] = useState(false)

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    navigate(newValue)
  }


  const tabs = [
    { value: 'oracle', label: 'Oracle', icon: <TableChartIcon/> },
    { value: 'analytics', label: 'Analytics', icon: <SsidChartIcon/> },
    { value: 'prediction', label: 'AI prediction', icon: <AutoGraphIcon/> },
    // { value: 'bots', label: 'AI trade', icon: <SmartButtonIcon/> }
  ]

  return (
    <Paper
      sx={{
        mr: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'start',
        minHeight: '90vh'
      }}
      elevation={0}
    >
      <Tabs
        orientation="vertical"
        value={location.pathname.split('/').filter(n => n)?.at(1) || 'analytics'}
        onChange={handleChange}
      >
        {tabs.map(({ value, label, icon }) => (
          <Tab
            key={value}
            sx={{
              width: '100%', justifyContent: 'start', alignItems: 'start', minWidth: 'max-content', p: '10px'
            }}
            value={value}
            label={
              <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                {icon}<P1 fontSize={10}>{label}</P1>
              </Box>
            }
          />
        ))}
      </Tabs>
      <MenuItem sx={{ p: '10px', gap: '5px', width: '100%' }} onClick={() => setSettingsOpened(true)}>
        <SettingsOutlinedIcon/>
        <P1 fontSize={10}>Settings</P1>
      </MenuItem>
      <SettingsDialog open={settingsOpened} onClose={() => setSettingsOpened(false)}/>
    </Paper>
  )
}
