import { FC, ReactNode, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../store/store'
import { useSnackbar } from 'notistack'
import { setMarketError } from '../store/market/slice'
import { setChartError } from '../store/chart/slice'
import { authActions } from '../store/auth/slice'

export const ErrorProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar: snackbar } = useSnackbar()

  const authError = useAppSelector(state => state.auth.error)
  const chartError = useAppSelector(state => state.chart.error)
  const marketError = useAppSelector(state => state.market.error)

  useEffect(() => {
    if (authError) {
      snackbar(authError, { variant: 'error' })
      dispatch(authActions.setError(null))
    }
    if (marketError) {
      snackbar(marketError, { variant: 'error' })
      dispatch(setMarketError(null))
    }
    if (chartError) {
      snackbar(chartError, { variant: 'error' })
      dispatch(setChartError(null))
    }
  }, [authError, marketError, chartError, dispatch, snackbar])
  return (
    <>
      {children}
    </>
  )
}
