import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { env } from '../constants/envVars'
import { localStorageService } from '../services/localStorageService'

export const apiRTK = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: env.API_ENDPOINT,

    prepareHeaders: (headers) => {
      const accessToken = localStorageService.getAccessToken()
      if (!headers.get('Authorization') && accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`)
      }
    }
  }),
  endpoints: () => ({})
})
