import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChartData, ChartDataSource, ChartType } from '../../types/models/chart'
import { Intervals } from '../../constants/constants'
import { localStorageService } from '../../services/localStorageService'
import { useAppSelector } from '../store'

export interface ChartState {
  interval: Intervals
  chartsData: ChartData[]
  chartType: ChartType
  normalizeData: boolean
  source: ChartDataSource
  loading: boolean
  error: string | null
}

const initialState: ChartState = {
  interval: localStorageService.getChartInterval() || Intervals.FIVE_MINUTES,
  chartType: localStorageService.getChartType() || 'candlestick',
  normalizeData: localStorageService.getChartNormalize() || false,
  loading: false,
  error: null,
  chartsData: [],
  source: ChartDataSource.CURRENCY
}

export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    setChartLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setChartError: (state, { payload }: PayloadAction<string | null>) => {
      state.error = payload
    },
    setInterval: (state, { payload }: PayloadAction<Intervals>) => {
      state.interval = payload
      localStorageService.setChartConfig({ interval: payload })
    },
    setChartType: (state, { payload }: PayloadAction<ChartType>) => {
      state.chartType = payload
      localStorageService.setChartConfig({ chartType: payload })
    },
    toggleNormalizeData: (state) => {
      state.normalizeData = !state.normalizeData
      localStorageService.setChartConfig({ normalize: state.normalizeData })
    },
    addChart: (state, { payload }: PayloadAction<ChartData>) => {
      if (!state.chartsData.find(chart => chart.symbol === payload.symbol)) {
        state.chartsData.push(payload)
        localStorageService.setChartConfig({ selectedCharts: state.chartsData.map(data => data.symbol) })
        if (state.chartsData.length > 1) {
          state.normalizeData = true
          state.chartType = 'line'
          localStorageService.setChartConfig({ normalize: true, chartType: 'line' })
        }
      }
    },
    removeChart: (state, { payload }: PayloadAction<string>) => {
      state.chartsData = state.chartsData.filter(chart => chart.symbol !== payload)
      localStorageService.setChartConfig({ selectedCharts: state.chartsData.map(data => data.symbol) })
    },
    setCharts: (state, { payload }: PayloadAction<ChartData[]>) => {
      state.chartsData = payload
      localStorageService.setChartConfig({ selectedCharts: state.chartsData.map(data => data.symbol) })
    },
    setChartDataSource: (state, { payload }: PayloadAction<ChartDataSource>) => {
      state.source = payload
    }
  }
})


export const {
  setChartLoading,
  setChartError,
  setInterval,
  setChartType,
  toggleNormalizeData,
  addChart,
  removeChart,
  setCharts,
  setChartDataSource
} = chartSlice.actions

export const ChartReducer = chartSlice.reducer

export const useChartState = () => {
  const state = useAppSelector(state => state.chart)
  return { ...state,}
}
