import { FC } from 'react'
import { P2 } from '../../../common/typography'
import { Button } from '@mui/material'
import { Tag } from '../../../types/models/news'

interface Props {
  tag: Tag
  onClick?: () => void
  isActive?: boolean
}

export const TagChip: FC<Props> = ({ tag, onClick, isActive }) => {
  return (
    <Button
      key={tag.id}
      sx={{
        p: '10px',
        borderRadius: '10px',
        bgcolor: isActive ? '#FFE7B9 !important' : undefined,
        '&:hover': { bgcolor: isActive ? '#FFE7B9' : undefined },
        color: isActive ? 'var(--main-text)' : 'inherit'
      }}
      onClick={onClick}
    >
      <P2 key={tag.id} textTransform="none">#{tag.name}</P2>
    </Button>
  )
}
