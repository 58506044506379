export interface OracleAsset {
  epic: string
  bid: number
  direction: number
  long: boolean
  name: string
  risk: number
  short: boolean
  status: OracleAssetStatus,
  type: OracleAssetType
}


export enum OracleAssetStatus {
  TRADEABLE = 'TRADEABLE',
  CLOSED = 'CLOSED',
}

export enum OracleAssetType {
  SHARES = 'SHARES',
  CRYPTOCURRENCIES = 'CRYPTOCURRENCIES',
  COMMODITIES = 'COMMODITIES',
  INDICES = 'INDICES',
  CURRENCIES = 'CURRENCIES',
  UNKNOWN = 'UNKNOWN'

}

export interface OracleAssetAnalytics {
  'action': boolean
  'order_side': 'Buy' | string
  'reasoning': string
  'stop_loss': number
  'take_profit': number
}
