import React, { FC, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Toolbar } from '../toolbar/toolbar'
import { Box } from '@mui/material'
import { useAppDispatch } from '../../store/store'
import { loadMarkets } from '../../store/market/actions'
import { updateAllChartData } from '../../store/chart/actions'
import { useChartState } from '../../store/chart/slice'
import { loadAllPlatforms, loadUserPlatforms } from '../../store/platform/actions'
import { useAuthState } from '../../store/auth/slice'

export const TradingLayout: FC = () => {
  const dispatch = useAppDispatch()
  const { isSubscriptionActive } = useAuthState()
  const { interval } = useChartState()
  const navigate = useNavigate()

  useEffect(() => {
    if (isSubscriptionActive) {
      dispatch(loadMarkets())
      // dispatch(loadBots())
      dispatch(loadAllPlatforms())
      dispatch(loadUserPlatforms())
    } else {
      navigate('/subscription')
    }
  }, [dispatch, navigate, isSubscriptionActive])

  useEffect(() => {
    dispatch(updateAllChartData())
  }, [interval, dispatch])

  return (
    <Box display="flex" px={1}>
      <Toolbar/>
      <Outlet/>
    </Box>
  )
}
