import { FC, useState } from 'react'
import { Box, SxProps } from '@mui/material'
import { useNewsState } from '../../../store/news/slice'
import { P2 } from '../../../common/typography'
import { useBreakpoint } from '../../../hooks/useBreakpoint'
import { Tag } from '../../../types/models/news'
import { AllTagsDialog } from './allTagsDialog'
import { TagChip } from './tagChip'

interface TagListProps {
  sx?: SxProps
  activeTag: Tag | null
  onChangeActiveTag: (tag: Tag) => void
}

export const TagList: FC<TagListProps> = ({ sx, activeTag, onChangeActiveTag }) => {
  const { tags } = useNewsState()
  const [allTagsDialogOpen, setAllTagsOpen] = useState(false)
  const uniqueTags: Tag[] = tags.reduce((acc, tag) => acc.find(t => t.name === tag.name) ? acc : [...acc, tag], [] as Tag[])
  let tagsLength = 4
  if (useBreakpoint('xs')) tagsLength = 4
  if (useBreakpoint('md')) tagsLength = 6


  const handleClickTag = (tag: Tag) => {
    if (onChangeActiveTag) {
      onChangeActiveTag(tag)
    }
  }
  return (
    <Box display="flex" sx={sx} gap="10px" flexWrap="wrap" alignItems="center">
      {uniqueTags.slice(0, tagsLength).map(tag => (
        <TagChip key={tag.id} tag={tag} onClick={() => handleClickTag(tag)} isActive={activeTag?.id === tag.id}/>
      ))}
      {tagsLength < tags.length && (
        <P2
          fontWeight="600"
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => setAllTagsOpen(true)}>
          See more
        </P2>
      )}
      <AllTagsDialog open={allTagsDialogOpen} onChangeActiveTag={onChangeActiveTag} onClose={() => setAllTagsOpen(false)} activeTag={activeTag}/>

    </Box>
  )
}
