import React, { ChangeEvent, FC, useState } from 'react'
import { Tag } from '../../../types/models/news'
import { Box, Dialog, SxProps } from '@mui/material'
import { H2 } from '../../../common/typography'
import { KitInput } from '../../../common/ui-kit/inputs/kitInput'
import { CloseIcon, SearchIcon } from '../../../common/assets/icons'
import { useNewsState } from '../../../store/news/slice'
import { TagChip } from './tagChip'


interface Props {
  open: boolean
  onClose: () => void
  activeTag: Tag | null
  onChangeActiveTag: (tag: Tag) => void
  sx?: SxProps
}

export const AllTagsDialog: FC<Props> = ({ sx, onChangeActiveTag, open, onClose, activeTag }) => {
  const { tags } = useNewsState()
  const [searchString, setSearchString] = useState('')

  const filteredTags = tags.filter(tag => tag.name.toLowerCase().includes(searchString.trim().toLowerCase()))

  const handleChangeSearchString = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value)
  }

  const handleTagClick = (tag: Tag) => {
    onChangeActiveTag(tag)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Box p="10px" sx={sx}>
        <H2 textAlign="center" mb="20px">Hashtags</H2>
        <KitInput
          value={searchString}
          onChange={handleChangeSearchString}
          startAdornment={<SearchIcon style={{ marginRight: '5px' }}/>}
          endAdornment={<CloseIcon style={{ cursor: 'pointer' }} onClick={() => setSearchString('')}/>}
          placeholder="Search by hashtag"
          sx={{ mb: '20px' }}
        />
        <Box display="flex" flexWrap="wrap" gap="10px" overflow="scroll" maxHeight="50vh" mb="20px">
          {filteredTags.map(tag => (
            <TagChip key={tag.id} tag={tag} onClick={() => handleTagClick(tag)} isActive={activeTag?.id === tag.id}/>
          ))}
        </Box>
      </Box>
    </Dialog>
  )
}
